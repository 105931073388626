
























































































import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import { ZuzhijiagouList } from "@/request/manage";
import { CreatorList } from "@/request/common";
import { JianchaHengjiList } from "@/request/storehouse";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
import SearchJigou from "@/components/search-jigou.vue";
import SearchCreator from "@/components/search-creator.vue";
import JiluRender from "../../components/jilu-render.vue";
import { clearFilter } from "@/utils/base";
@Component({
  components: {
    MainHeader,
    NavLeft,
    SearchJigou,
    SearchCreator,
    JiluRender,
  },
})
export default class Name extends mixins(listMixin) {
  private institutions: any = [];
  private creators: any[] = [];
  private infos: any[] = [];
  private get center() {
    return this.$store.state.center;
  }
  private goRouter(path: any) {
    clearFilter();
    this.$router.push(path);
  }
  /**
   * @description 获得可选机构列表
   */
  private fetchHospitals() {
    const params: any = {
      params: {
        project_id: this.center.project_id,
      },
    };
    if (this.filters.project_id == "全部") {
      params.params.project_id = "";
    }
    ZuzhijiagouList(this, params).then((data: any) => {
      this.institutions = data;
      this.getCreatorsList();
    });
  }
  /**
   * @description 获得可选创建者列表
   */
  private getCreatorsList() {
    const params: any = {
      project_id: this.center.project_id,
      institution_ids: [this.filters.institution_id],
    };
    if (this.filters.project_id == "全部") {
      params.project_id = "";
    }
    CreatorList(this, params).then((data: any) => {
      this.creators = data;
    });
  }
  /**
   * @description 选择机构改变
   */
  private institutionChange(e: any) {
    this.filters.institution_id = e;
    // 重置创建人
    this.filters.user_ids = [];
    // 搜索
    this.search();
  }
  /**
   * @description 选择创建人改变
   */
  private creatorChange(e: any) {
    this.filters.user_ids = e;
    // 搜索
    this.search();
  }
  getList() {
    const params: any = {
      institution_id: this.filters.institution_id,
      project_id: this.center.project_id,
      creator: this.filters.user_ids,
      order_field: this.filters.sort_field,
      order_value: this.filters.sort_value,
      current_page: this.filters.current_page,
      page_count: this.filters.page_count,
      search: this.filters.search,
      type: this.filters["类型"],
    };
    localStorage.setItem("markFilter", JSON.stringify(this.filters));
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    JianchaHengjiList(this, params, loading).then((data: any) => {
      loading.close();
      data.jianchas.forEach((item: any) => {
        item.ifShowDetail = true;
      });
      this.infos = data.jianchas;
      this.filters.total_page = data.total_page;
      this.filters.total_count = data.total_count;
      if (this.infos.length === 0) {
        this.ifShowEmpty = true;
      } else {
        this.ifShowEmpty = false;
      }
    });
  }
  /**
   * @description 初始化
   */
  mounted() {
    this.filters.institution_id = "";
    this.filters.user_ids = [];
    this.filters["类型"] = "";
    this.filters.page_count = 20;
    const d = localStorage.getItem("markFilter") || "";
    if (d) {
      this.filters = JSON.parse(d);
    }
    this.fetchHospitals();
    this.getCreatorsList();
    this.getList();
  }
}
